import React from 'react';
import PropType from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

/**
* Line chart.
*/
const propTypes = {

}
const LineChart = ( { data, ...p } ) => {


  /* ------ = Lifecycle. = --------------------------------------------------------------------- */

  const [ dataProp, setDataProp ] = React.useState( [] );

  React.useEffect( () => {

    setTimeout( () => {
      setDataProp( data );
    }, 1000 );

  }, [] );

  /* ------ = Output. = --------------------------------------------------------------------- */

  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: 13,
          fontFamily: 'proxima-nova',
          fontWeight: 500,
          color: 'black',
          paddingTop: 20,
        }
      }
    },
    legends: {
      text: {
        fontSize: 15
      }
    }
  };


  return(

    <div
    className="LineChart"
    style={ {
      height: '400px',
    } }
    >
      <ResponsiveLine
      data={ dataProp }
      margin={ { top: 50, right: 110, bottom: 50, left: 60 } }
      xScale={ { type: 'point' } }
      yScale={ { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false, } }
      colors={ { datum: 'color' } }
      theme={ theme }
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      enablePointLabel={ true }
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      legends={
        [
          {
            anchor: 'top',
            direction: 'row',
            justify: false,
            translateY: -30,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 120,
            itemHeight: 40,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            // effects: [
            //   {
            //     on: 'hover',
            //     style: {
            //       itemBackground: 'rgba(0, 0, 0, .03)',
            //       itemOpacity: 1
            //     }
            //   },
            // ]
          }
        ]
      }
      motionStiffness={100}
      motionDamping={15}
      { ...p }
      />
    </div>
  );

}
LineChart.propTypes = propTypes;

export default LineChart;